import React from "react";
import { useParams } from 'react-router-dom';

// import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react';

const Video = () => {
    const { id } = useParams();

    const decodedId = decodeURIComponent(id)

    if(decodedId.includes("microsoft")) {
        return <img alt="" src={id} width="100%" height = "100%" />
    }
    else {
        return <img alt="" src={"https://drive.google.com/uc?export=download&id=" + id} width="100%" height = "100%" />
    }
};
export default Video;