import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";


export var firebaseConfig = {
    apiKey: "AIzaSyAsimcuMPnYxRKE0zOn4-E8Qpu2sPchLmQ",
    authDomain: "streams3-efbe7.firebaseapp.com",
    databaseURL: "https://streams3-efbe7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "streams3-efbe7",
    storageBucket: "streams3-efbe7.appspot.com",
    messagingSenderId: "175673415140",
    appId: "1:175673415140:web:1ac8bea9416a62cd8be3f1"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
//export const firestore = firebase.firestore();
export const database = firebase.database();

