import React, {useState, useEffect, useCallback} from "react";
import { database } from "../firebase";
import { useParams,Link } from 'react-router-dom';
import {formatTime,humanFileSize } from './All'
import { getVideoEntry } from '../helper'

const User = () => {
    const { id } = useParams();
    const [files, setFiles] = useState([])
    const [user, setUser] = useState([])
    const [deletingFile, setDeletingFile] = useState("")
    const [deletingAll, setDeletingAll] = useState(false)

    const  fetchData = useCallback(async() => {
        let userRef = await database.ref('users/' + id);
        userRef.on('value', (snapshot) => {
            const userObject = snapshot.val()
            if(userObject) {
                let videos = []
                for (const [key,value] of Object.entries(userObject.videoList)) {
                    videos.push(value)
                }

                videos = videos.sort(function(a, b) {
                    return b.date - a.date;
                }).filter(v => v.status === '');
                if(!videos || videos.length === 0)
                {
                    window.location = "/all"
                }
                else if(videos && videos.length === 1)
                {
                    window.location = "/video/" + id + "/" + videos[0].filename
                }
                setFiles(videos)
                setUser(userObject)
                setDeletingFile("")
            }
        });
    }, [id])

    const deleteVideo = useCallback((fileName) => {
        setDeletingFile(fileName)
        database.ref('users/' + id).on('value', (snapshot) => {
            const userData = snapshot.val()
            if(userData) {
                const videoId = fileName.replace('.mp4', '')
                database.ref('users/' + id +'/videoList/' + videoId + '/status')
                    .set('deleted')

                let videos = []
                for (const [key,value] of Object.entries(userData.videoList)) {
                    videos.push(value)
                }

                let validVideos = videos.sort(function(a, b) {
                    return b.date - a.date;
                }).filter(v => v.status === '');
                if(validVideos && validVideos.length > 0) {
                    database.ref('users/' + id + '/lastRecording').set(validVideos[0].date)
                }
                setFiles(files.filter((f) => f.filename != fileName))
                setDeletingFile("")
            }
        });
    }, [id, files, setFiles, setDeletingFile])

    const deleteAllVideos = useCallback(() => {
        setDeletingAll(true)
        files.forEach(async (video) => {
            const videoId = video.filename.replace('.mp4', '')
            database.ref('users/' + id +'/videoList/' + videoId + '/status').set('deleted')
            }
        )
        setDeletingAll(false)
        fetchData()
    }, [id, fetchData, files, setDeletingAll])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <div>
        <div className="mgt-5 mgb-5">
            <Link to="/all" className="btn btn-light mgr-10 ">Back</Link>
            <a href={`/manage/${id}`} className="btn btn-light mgr-10 float-right">manage</a>
        </div>
        <hr/>
        {!deletingAll && files.map(video => {
            const videoEntry = getVideoEntry(video, user)
            return <div key={video.filename}>
                <br/>
                <a href={videoEntry.url} target="_blank" rel="noreferrer">{videoEntry.day}.{videoEntry.month}.{videoEntry.year} {videoEntry.hours}:{videoEntry.minutes} - {videoEntry.username} - {videoEntry.provider}</a>
                <br/>
                {video.metadata ?
                    <span>{formatTime(video.metadata.duration)} ({humanFileSize(video.metadata.size)})<br/></span>
                    : ''
                }
                <br/>
                {deletingFile != video.filename &&
                    <button className="btn btn-secondary" onClick={() => {deleteVideo(video.filename)}}>Delete</button>
                }
                <br/>
                <br/>
                <Link to={`/video/${id}/${video.filename}`} className="btn btn-light mgr-10 ">
                    <img alt="" src={videoEntry.imageUrls.imageSmall} className="preview-image" />
                </Link>
                <hr/>
                </div>
        })}
        <br/>
        <hr/>
        <br/> 
        <button className="btn btn-secondary" onClick={() => {deleteAllVideos()}}>Delete All Videos</button>
        </div>
  
};
export default User;