import React, {useContext} from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Empty from "./Empty";
import SignIn from "./SignIn";
import ProfilePage from "./ProfilePage";
import All from "./All";
import Manage from "./Manage";
import User from "./User";
import Video from "./Video";
import Image from "./Image";

import { UserContext } from "../providers/UserProvider";

function Application() {
    const user = useContext(UserContext);
  return (
    user ?
    <Router>
        <Switch>
            <Route path="/" exact>
                <Empty />
            </Route>
            <Route path="/profile" exact>
                <ProfilePage />
            </Route>
            <Route path="/all" exact>
                <All />
            </Route>
            <Route path="/manage/:id" exact>
                <Manage />
            </Route>
            <Route path="/user/:id" exact>
                <User />
            </Route>
            <Route path="/video/:user/:fileName" exact>
                <Video />
            </Route>
            <Route path="/image/:id" exact>
                <Image />
            </Route>
            {/* <Route path="/detail/:id">
            <DetailPage />
            </Route>
            <Redirect path="/create" /> */}
        </Switch>
    </Router>
      :
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Empty />
                </Route>
                <Route path="/signin" exact>
                    <SignIn />
                </Route>
            </Switch>
        </Router>

  );
}
export default Application;