import React, {useState, useEffect, useCallback} from "react";
import { database } from "../firebase";
import { useLocation, Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getVideoEntry } from '../helper'

export function formatTime(seconds) {
    return [
        parseInt(seconds / 60 / 60),
        parseInt(seconds / 60 % 60),
        parseInt(seconds % 60)
    ]
        .join(":")
        .replace(/\b(\d)\b/g, "0$1")
}

export function humanFileSize(size) {
    var i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

function getEntry(value) {
    return {
        video:
            {filename: value.filename
            },
        user:
            {
                username: value.userId,
                userId: value.userId
            }
    }
}

const All = () => {
    const initialItemsSize = 15
    const [files, setFiles] = useState([])
    const [processing, setProcessing] = useState([])
    const [processWaits, setProcessingWaits] = useState([])
    const [recording, setRecording] = useState([])
    const [itemsDisplayed, setItemsDisplayed] = useState(initialItemsSize)
    const [allVideosSize, setAllVideosSize] = useState(0)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sorting = searchParams.get('sorting');

    const deleteVideo = (userId, fileName) => {
        database.ref('users/' + userId).on('value', (snapshot) => {
            const userData = snapshot.val()
            if(userData) {
                const videoId = fileName.replace('.mp4', '')
                database.ref('users/' + userId +'/videoList/' + videoId + '/status')
                    .set('deleted')

                let videos = []
                for (const [key,value] of Object.entries(userData.videoList)) {
                    videos.push(value)
                }

                let validVideos = videos.sort(function(a, b) {
                    return b.date - a.date;
                }).filter(v => v.status === '');
                if(validVideos && validVideos.length > 0) {
                    database.ref('users/' + userId + '/lastRecording').set(validVideos[0].date)
                }
                fetchData()
            }
        });
    }

    useEffect(() => {
        let s = 0
        files?.forEach((e) => s+= e.size)
        setAllVideosSize(s)
    }, [files])

    async function fetchData() {
        let recordings = []
        let processings = []
        let process_waits = []

        await database.ref('queue').once('value',
            function(recordSnapshot) {
                const entry = recordSnapshot.val()
                console.log(entry)
                if(entry && entry.recordings) {
                    for (const [key, value] of Object.entries(entry.recordings)) {
                        recordings.push(getEntry(value))
                    }
                }
                if(entry && entry.processing) {
                    for (const [key, value] of Object.entries(entry.processing)) {
                        if(value.status === 'process') {
                            process_waits.push(getEntry(value))
                        }
                        else {
                            processings.push(getEntry(value))
                        }
                    }
                }
            })

        await database.ref('users')
            .once('value',function(usersSnapshot) {
                let entries = []

                usersSnapshot.forEach((userSnapshot) => {
                    const entry = userSnapshot.val()
                    let videos = []
                    let sizes = 0
                    if(entry.videoList) {
                        let validVideos = []
                        for (const [key, value] of Object.entries(entry.videoList)) {
                            videos.push({user: entry, video: value})
                            if(value.status === '') {
                                validVideos.push(value)
                                if (entry.lastRecording < value.date) {
                                    entry.lastRecording = value.date
                                }
                            }
                        }

                        entry.validVideos = validVideos
                        entry.size = 0
                        entry.validVideos?.forEach(v => {
                            if(v.metadata) {
                                entry.size += v.metadata.size
                            }
                        })
                        entries.push(entry)

                        if(sorting !== "size") {
                            entries = entries.filter(m => m.videoList).sort(function(a, b) {
                                return b.lastRecording - a.lastRecording;
                            });
                        }
                        else {
                            entries = entries.sort(function(a, b) {
                                return b.size - a.size;
                            });
                        }
                        setFiles(entries)
                    }
                })
                setRecording(recordings)
                setProcessing(processings)
                setProcessingWaits(process_waits)


                if(sorting !== "size") {
                    entries = entries.filter(m => m.videoList).sort(function(a, b) {
                        return b.lastRecording - a.lastRecording;
                    });
                }
                else {
                    entries = entries.sort(function(a, b) {
                        return b.size - a.size;
                    });
                }

                setFiles(entries)
            })
    }

    useEffect(() => {
        
        fetchData();
    }, []);

    function handleClick(e) {
        e.preventDefault();
        setItemsDisplayed(itemsDisplayed + initialItemsSize)
      }

    return <div>
        <div className="mgt-5 mgb-5">
            <a href="/all" className="btn btn-light mgr-10">Reload</a>
            <a href="/manage/wow" className="btn btn-light mgr-10 float-right">manage</a>
        </div>
        <hr/>
        <div className="mgt-5 mgb-5">
            <div className="btn btn-light mgr-10">{ allVideosSize > 0 ? humanFileSize(allVideosSize): ""}</div>
            <a href={sorting === "size" ? "/all" : "/all?sorting=size"} className="btn btn-light mgr-10 ">{sorting === "size" ? "size" : "date"}</a>
        </div>
        
        <hr/>
        {
            recording &&
                <React.Fragment>
                <div className="mgt-5 mgb-5">
                    recording: <br/>
                    {
                        recording.map((entry) => {
                            const videoEntry = getVideoEntry(entry.video, entry.user)
                            return <span key={`recording-${entry.video.filename}`}><a href={videoEntry.url} target="_blank" rel="noreferrer">{videoEntry.day}.{videoEntry.month}.{videoEntry.year} {videoEntry.hours}:{videoEntry.minutes} - {videoEntry.username} - {videoEntry.provider}</a> <br/></span>
                        })
                    }
                </div>
                <hr/>
                </React.Fragment>
        }
        {
        processing &&
        <React.Fragment>
            <div className="mgt-5 mgb-5">
                {
                    processing.map((entry) => {
                        const videoEntry = getVideoEntry(entry.video, entry.user)
                        return <span key={`processessing-${entry.video.filename}`}>{videoEntry.day}.{videoEntry.month}.{videoEntry.year} {videoEntry.hours}:{videoEntry.minutes} - {videoEntry.username} ({entry.video.filename})<br/></span>
                    })
                }
            </div>
            <hr/>
        </React.Fragment>
    }
        {
            processWaits &&
            <React.Fragment>
                <div className="mgt-5 mgb-5">
                    {
                        processWaits.map((entry) => {
                            const videoEntry = getVideoEntry(entry.video, entry.user)
                            return <span key={`processessing-${entry.video.filename}`}>{videoEntry.day}.{videoEntry.month}.{videoEntry.year} {videoEntry.hours}:{videoEntry.minutes} - {videoEntry.username} ({entry.video.filename})<br/></span>
                        })
                    }
                </div>
                <hr/>
            </React.Fragment>
        }
        {
            files.slice(0, itemsDisplayed).map(e => {
                let validVideos = e.validVideos.sort(function(a, b) {
                    return b.date - a.date;
                }).filter(v => v.status === '');
                if(validVideos && validVideos.length > 0) {
                    const lastVideo = validVideos[0]
                    const videoEntry = getVideoEntry(lastVideo, e)

                    return <div key={e.userId} className="block-all">
                        <div class="flex-container">

                            <div class="flex-child">
                                <a href={videoEntry.url} target="_blank" rel="noreferrer">
                                <b>{videoEntry.username} {lastVideo.metadata && `(${formatTime(lastVideo.metadata.duration)})`} - {humanFileSize(e.size)} </b><br></br>
                                {videoEntry.day}.{videoEntry.month}.{videoEntry.year} {videoEntry.hours}:{videoEntry.minutes} -  {lastVideo.metadata && `(${humanFileSize(lastVideo.metadata.size)})`} -  {videoEntry.provider}<br></br>
                               </a>
                        
                            </div>

                            <div class="flex-child">
                                <button className="btn btn-secondary" onClick={() => { deleteVideo(videoEntry.username, lastVideo.filename)}}>Delete</button>
                            </div>

                        </div>
                        
                        <Link to={`/user/${e.userId}`}>
                            <LazyLoadImage
                                className="preview-image-all"
                                alt=""
                                src={videoEntry.imageUrls.imageSmall}
                                 />

                        </Link>
                    </div>
                }
                else {
                    return ''
                }
            })
        }

        <div className="mgt-5 mgb-5">
        <button onClick={handleClick}  className="btn btn-light mgr-10">
            more
        </button>
        </div>
    </div>
  
};
export default All;